<div *ngIf="data.categoryMenuType!=6&&data.categoryMenuType!=7&&type!='image'">
<ng-container *ngIf="list && list.length >0">
  <div class="theme-container" *ngFor="let subChild of list" (click)="goToCategory(subChild)">

    <span [style.color]="data.categoryColor" style="font-size: 15px;line-height: 30px;" class="subMenus pointer theme-container" *ngIf="display && data.categoryMenuType===2">  
      {{subChild.categoryName}}
    </span>

    <span class="subMenus pointer theme-container" style="font-size: 15px;line-height: 30px;" *ngIf="display && data.categoryMenuType!==2">  
      {{subChild.categoryName}}
    </span>

    <a *ngIf="!display && data.categoryMenuType===2" style="font-size: 15px;line-height: 30px;" (click)="goToCategory(subChild)" [style.color]="data.categoryColor" mat-menu-item>
      {{subChild.categoryName}}</a>

      <a *ngIf="!display && data.categoryMenuType!==2" style="font-size: 15px;line-height: 30px;" (click)="goToCategory(subChild)"  mat-menu-item>
        {{subChild.categoryName}}</a>

    <calsoft-nested-child [data]="data" [list]="subChild.subcategories"></calsoft-nested-child>

  </div>
</ng-container>
</div>

<div *ngIf="data.categoryMenuType==6&&type!='image';">
  <ng-container *ngIf="list && list.length >0">
    <div class="theme-container" *ngFor="let subChild of list" (click)="goToCategory(subChild)">
  
      <div fxLayout="row" fxLayoutAlign="start center">
        <img   *ngIf="displayImageInMenu!=undefined&&displayImageInMenu==1;"  style="width: 30px;" 
        onerror="this.src='assets/images/error/no-picture-icon.jpg';" [src]="serverPath + subChild.image" [alt]="subChild
        .categoryName">

   
        <div fxFlex="none"><span [ngStyle]="{'color': data.categoryColor, 'font-size': '12px', 'line-height': '30px'}"
          class="subMenus pointer theme-container">  
      {{subChild.categoryName}}
    </span>
    
        
          
          </div>
        </div>
      
  
      <calsoft-nested-child [data]="data" [displayImageInMenu]="displayImageInMenu" [list]="subChild.subcategories"></calsoft-nested-child>
  
    </div>
  </ng-container>
  </div>

  <div *ngIf="type=='image'" >
 
    <ng-container *ngIf="list && list.length >0" >
      <!-- <div class="theme-container-grid" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
        <div class="theme-container" fxFlex="20" *ngFor="let subChild of list" (click)="goToCategory(subChild)">
          <div fxFlex="100">
            <img style="width: 100px; height: 60px; border-radius: 5px;" onerror="this.src='assets/images/error/no-picture-icon.jpg';"
                 [src]="serverPath + subChild.image" [alt]="subChild.categoryName">
          </div>
        </div>
      </div> -->
      <div class="tab-content" >
     
        <div  fxLayout="row wrap" [fxLayoutAlign]="submenu.textAlign!=undefined ? submenu.textAlign : 'start start'"  fxLayoutGap="16px">
          <div (click)="goToCategory(subChild)" class="brand"  fxLayout="row wrap" style="max-width:min-content"
           [fxFlex]="100/data.categroySubmenuColumn" 
           *ngFor="let subChild of list">
            <img style=" border-radius: 5px;" onerror="this.src='assets/images/error/no-picture-icon.jpg';"
            [src]="serverPath + subChild.image" [alt]="subChild.categoryName">
            <calsoft-nested-child [data]="data" [type]="'image'" [submenu]="subChild"
							[list]="subChild.subcategories"></calsoft-nested-child>
         
          </div>
        </div>
        </div>
        <!-- <div class="tab-content">
          <div fxLayout="row wrap"  style="width:max-content" [fxLayoutAlign]="submenu.textAlign ? submenu.textAlign : 'start start'" fxLayoutGap="16px">
            <div 
              (click)="goToCategory(subChild)" 
              class="brand" 
              style="max-width:min-content"
              [fxFlex]="(100/data.categroySubmenuColumn)- 16px" 
              fxFlex="1 1 calc(100% / (list.length < 4 ? list.length : data.categroySubmenuColumn) - 16px)" 
              *ngFor="let subChild of list">
              <img style="border-radius: 5px;" onerror="this.src='assets/images/error/no-picture-icon.jpg';" [src]="serverPath + subChild.image" [alt]="subChild.categoryName">
              <calsoft-nested-child [data]="data" [type]="'image'" [submenu]="subChild" [list]="subChild.subcategories"></calsoft-nested-child>
            </div>
          </div>
        </div> -->
    </ng-container>
    </div>